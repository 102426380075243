.custom-table .ant-table-thead > tr > th {
    color: #2e3559; /* Set your desired header text color */
  }
  
  .popover-switch {
    max-height: 200px;
    overflow: auto;
  }
  .custom-popover {
    background-color: #f9f9f9;
    color: #ffffff;
    border: 1px solid #bcbbbb;
  }
  
  .custom-popover :hover {
    cursor: pointer;
  }
  
  .play-report-table .ant-table-wrapper .ant-table-tbody .ant-table-row > td {
    padding: 8px 16px !important;
  }
  