.nav_bar{
    background-color: dodgerblue;
    height: 50px;
    width : 100vw;
    display: flex;
    align-items: center;
    font-size: large;
    font-weight: 700;
}

.header{
    position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
