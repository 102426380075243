.left-drawer {
    position: relative;
    top:0;
    left : -300px;
    transition: left 0.5s ease-in-out;
  }
  
  .left-drawer.open {
    left : 0;
    transition: left 0.5s ease-in-out;
  }