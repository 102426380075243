.wrapper {
	/* width: 100% !important; */
	max-width: 600px;
	margin: auto;
	/*background:red;*/
}	
.wrapperLandscape {
	display:flex;
	justify-content:center;
}		
.mainwrapper {
	position: relative;
	/*height: 100%;	*/	
	/* padding-bottom: 56.25%; */
	/*background:green;*/
}
.mainwrapperIframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100%;
}
