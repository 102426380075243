.Editor{
    background-color:#181d1f;
    border: '1px solid #050506';
    /* overflow: scroll; */
    position: relative;
    z-index: 1;
}
.drawArea{
   top : 40px;
   z-index: 1;
}
.item{
    text-align: left;
}
.textarea{
    border-radius: 0%;
    padding: 0px;
    border: 0px;
    background-color: transparent;
}

.item::-webkit-scrollbar,.drawArea::-webkit-scrollbar {
    display: none;
}

.item:hover::-webkit-scrollbar,.drawArea:hover::-webkit-scrollbar {
    display: none;
}

.item::-webkit-scrollbar-track,.drawArea::-webkit-scrollbar-track {
    background-color: #f5f0f047;
}

.item::-webkit-scrollbar-thumb,.drawArea::-webkit-scrollbar-thumb {
    background-color: #ffffff5d;
}

.resize{
    position: absolute;
    left: 50%;
    top: -18px;
    width: 18px;
    height: 18px;
    margin-left: -9px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}
.resizable-handler{
    z-index: 100;
    /* background-color: black; */
}

/************************* player css ******************************/
.video-js.editor-player .vjs-control-bar{
    position: absolute !important;
    bottom : -40px !important;
}

.show{
    display : block !important;
    padding-right: 0 !important;
}
.video-js.editor-player .vjs-time-control{
    min-width: 1em !important; 
}
.vjs-remaining-time{
    width : 60px !important;
}
.showTimeline{
    visibility:  hidden !important;
}

@keyframes shine {
    0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    
 }
 
 .l1 {
    display: grid;
  }
  .l1:before,
  .l1:after {
    content: "";
    grid-area: 1/1; 
    background: #514b82;
    clip-path: polygon(0 0,100% 0,100% 100%,75% 25%,0 0,0 100%,100% 100%,25% 75%,0 0);
    animation: l20 0.5s infinite linear;
  }
  .loader:after {
    clip-path: polygon(0 0,76% 24%,100% 100%,24% 76%);
    --s:0;
  }
  @keyframes l20 {
    0%      {transform: perspective(100px) rotate3d(1,var(--s,1),0,0)      } 
    25%     {transform: perspective(100px) rotate3d(1,var(--s,1),0,-90deg) }
    25.01%  {transform: perspective(100px) rotate3d(1,var(--s,1),0,-90deg) } 
    40%,60% {transform: perspective(100px) rotate3d(1,var(--s,1),0,-180deg)} 
    75%     {transform: perspective(100px) rotate3d(1,var(--s,1),0,-270deg)}
    75.01%  {transform: perspective(100px) rotate3d(1,var(--s,1),0,-270deg)} 
    90%,100%{transform: perspective(100px) rotate3d(1,var(--s,1),0,-360deg)}
  }
