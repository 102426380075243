.btn-disabled {
  color: #fff;
  background: transparent;
  transition: all 0.5s ease;
}

.google-img {
  width: 15px;
  height: 15px;
}
.ant-form-item {
  margin-bottom: 20px;
}

/* .login-btn .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 4px;
} */
.signup-banner {
  background: url("../../../assests/commonImages/userModuleImages/newLoginbg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 434px;
}

@media (max-width: 1024px){
  .signup-banner{
    display: none !important;
  }
}
.form-wrapper {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 100vh; */
  margin-top: 70px;
}
.banner-info {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* height: 100vh; */
  padding-top: 100%;
}
.banner-info h4 {
  color: white !important;
}
.copyright-section {
  bottom: 0;
  padding-left: 16px;
}

/* Submit button styling */
.ant-btn-primary {
  background: linear-gradient(90deg, #1c68eb 2.38%, #1c68eb 100%) !important;
}

/* .ant-btn-primary:disabled {
  background: linear-gradient(90deg, #ebebeb 2.38%, #ebebeb 100%) !important;
} */
/* .ant-btn-primary:disabled:hover {
  background: linear-gradient(90deg, #ebebeb 2.38%, #ebebeb 100%) !important;
} */
.ant-btn-primary:active {
  background: #0943a6 !important;
}

.ant-btn-primary:hover {
  background: linear-gradient(90deg, #2d1ceb 0%, #0944a8 100%) !important;
}

.ant-btn-primary:disabled {
  cursor: auto;
  border-color: #BFBFBF;
  color: #BFBFBF !important;
  background: transparent !important;
}

.google-signup-btn:hover {
  color: #000 !important;
  border-color: #d9d9d9 !important;
}

/* for error message */
.redError {
  color: red;
}

.ant-input::placeholder {
  color: #9a9a9a;
}
.ant-input:focus{
  border-color: #aab2ff !important;
  box-shadow: 0 0 10px rgba(33, 33, 33, 0.2) !important;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: #ff4d4f !important;
}
.ant-input:hover {
  border-color: #aab2ff !important;
  /* box-shadow: 0 0 10px rgba(33,33,33,.2) !important */
}
.ant-input-affix-wrapper-focused {
  border-color: #aab2ff !important;
  box-shadow: 0 0 10px rgba(33, 33, 33, 0.2) !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #aab2ff !important;
  /* box-shadow: 0 0 10px rgba(33,33,33,.2) !important */
}
.ant-form-item-control-input-content:hover {
  border-color: #aab2ff !important;
}
.ant-form-item-control-input-content:focus {
  border-color: #aab2ff !important;
}
.ant-form-item-control-input:focus {
  border-color: #aab2ff !important;
}

/* Animation for email verify page */
.email-verify {
  animation: fade-in 1.3s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
} 

/* Animation for email change */
.change-email{
  animation: fade-in 1.3s ease-in-out;
}

@keyframes fade-out {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.email-verify .email-section {
  animation: fade-in 1.3s ease-in-out;
}


/* ON FORGOT PASSWORD PAGE */
.forgot-password {
  animation: fade-in 1.3s ease-in-out;
}

/* ON RESET PASSWORD PAGE */
.reset-password {
  animation: fade-in 1.3s ease-in-out;
}

/* EMAIL OTP CONFIRM */

.opt-container-email {
  @apply flex justify-between gap-4;
}
.opt-container-email input {
  @apply !mr-0 flex-1 py-6 outline-none;
  border-radius: 4px !important;
  border: 1px solid #848FC9 !important;
  /* @apply !mr-0 flex-1 py-5 outline-none; */
  width: 44px !important;
  height: 40px !important;
  font-size: 20px !important;
  font-weight: 500;
}
.opt-container-email input:focus{
  outline: 1px solid #1963E1;
  border: 1px solid #1963E1;
  box-shadow: 0px 0px 4px 0px #19191a;
  border-color: transparent;
  outline-offset: 0;
  /* width: 24px !important; */
  /* height: 60px !important; */
  /* font-size: 26px !important; */
  /* font-weight: 600 !important; */
}

/* Add the following CSS classes for animations */
/* .form-wrapper {
  transition: opacity 0.5s ease; 
}

.form-wrapper.exit {
  opacity: 0; 
}

.text-center {
  transition: opacity 0.5s ease; 
  opacity: 0; 
}

.text-center.entry {
  opacity: 1; 
} */
