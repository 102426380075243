@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body,
.ant-input, 
.ant-col,
.ant-row, 
.ant-select
.ant-select-selection
.ant-select-arrow, 
.ant-btn
.ant-btn-primary
.ant-btn-danger
.ant-btn-link, 
.ant-input
.ant-input-search
.ant-input-group, 
.ant-form
.ant-form-item
.ant-form-label
.ant-form-control, 
.ant-table
.ant-table-thead
.ant-table-tbody
.ant-table-row
.ant-table-cell,
.ant-modal
.ant-modal-title
.ant-modal-content
.ant-modal-footer,
.ant-dropdown
.ant-dropdown-menu
.ant-dropdown-menu-item 
{
    font-family: 'Inter', sans-serif !important;
    /* overflow: hidden; */
}

/* :where(.css-dev-only-do-not-override-zjzpde)[class^="ant-col"],
:where(.css-dev-only-do-not-override-zjzpde)[class*=" ant-col"] {
  font-family: 'Inter', sans-serif !important;
} */

:root {
  --toastify-toast-min-height: 52px !important;
}

.Toastify__toast{
  font-size: 13px !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  overflow: hidden;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
 ::-webkit-scrollbar-track {
  background-color: transparent;
}

.blur-background {
  filter: blur(5px); /* Adjust the blur strength as needed */
  pointer-events: none; /* Prevent interactions with blurred elements */
}

/* REACT SLICK CSS */
.slick-arrow{
  background-color: transparent !important;
  border-radius: 50% !important;
  /* font-size: 200px !important; */
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  top: 120px !important;
  z-index: 10;
}

.slick-prev{
   left: 8px !important;
}

.slick-next{
    right: 8px !important;
}