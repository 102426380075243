.editor-collapse .ant-collapse-header .ant-collapse-header-text {
    color: white !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-align: left;
}
.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    color: white !important;
    padding: 0 !important;
}
.editor-collapse.ant-collapse>.ant-collapse-item >.ant-collapse-header {

    padding: 0 !important;
}
.editor-collapse .ant-collapse-horizontal{
    border-color: #2B2B2C !important;
}
.editor-collapse2 .ant-collapse-header .ant-collapse-header-text {
    color: white !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-align: left;
}
.editor-collapse2.ant-collapse>.ant-collapse-item >.ant-collapse-header {

    padding: 0 !important;
}
.editor-collapse2 .ant-collapse-horizontal{
    border-color: #2B2B2C !important;
}
/* .ant-switch{
    background:rgba(0, 0, 0, 0.25);
} */
/* .ant-switch{
    background:rgba(0, 0, 0, 0.25);
    height: 20px !important;
    width: 50px;
}
.ant-switch .ant-switch-inner {
    display: block;
    overflow: hidden;
    border-width: 1px !important;
    border-color: #2B2B2C !important;
    border-radius: 100px;
    height: 20px;
    
    padding-inline-start: 24px;
    padding-inline-end: 9px;
    transition: padding-inline-start 0.2s ease-in-out,padding-inline-end 0.2s ease-in-out;
}
.ant-switch .ant-switch-handle::before {
    position: inherit;
    top: 2px;
    inset-inline-end: 0;
    bottom: -1px;
    inset-inline-start: 0;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    transition: all 0.2s ease-in-out;
    content: "";
}
.ant-switch.ant-switch-checked {
    background: #0F40EC !important;
} */

.demo .ant-select-selector{
    background-color: #6792BA !important;
}
.f input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.editor-switch .ant-switch{
    background:rgba(0, 0, 0, 0.25);
    height: 20px !important;
    width: 50px;
}
.editor-switch .ant-switch .ant-switch-inner {
    display: block;
    overflow: hidden;
    border-width: 1px !important;
    border-color: #2B2B2C !important;
    border-radius: 100px;
    height: 20px;
    padding-inline-start: 21px;
    padding-inline-end: 9px;
    transition: padding-inline-start 0.2s ease-in-out,padding-inline-end 0.2s ease-in-out;
}
.editor-switch .ant-switch .ant-switch-handle::before {
    position: inherit;
    top: 2px;
    inset-inline-end: 0;
    bottom: -1px;
    inset-inline-start: 0;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    transition: all 0.2s ease-in-out;
    content: "";
}
.editor-switch .ant-switch.ant-switch-checked {
    background: #0F40EC !important;
}