body{
  overflow: auto;
}
.video-player .video-js .vjs-control-bar {
  position: absolute !important;
  /* bottom: -5vw !important; */
  /* width: 100vw !important; */
  /* height: 8vw !important;
  justify-content: space-evenly !important; */
  z-index:100000;
  pointer-events: auto !important;
}

.vjs-big-play-button{
	z-index : 100000;
}

/* .video-player .video-js .vjs-control {
  width: 6vw !important;
} */
.show {
  display: block !important;
}

/* .video-player .video-js  .vjs-time-control {
  font-size: 3vw !important;
  display: flex !important;
  align-items: center !important;
  padding : 0 !important;
  min-width: 0 !important;
  line-height: 8vw !important; 
} */
/* .video-player .video-js .vjs-time-control.vjs-time-divider.show {
  width: 1vw !important;
}
.video-player .video-js .vjs-duration.vjs-time-control.vjs-control.show {
  padding-left: 0 !important;
} */
 .vjs-play-control.vjs-control.vjs-button.vjs-paused:focus,.vjs-play-control.vjs-control.vjs-button.vjs-playing:focus{
  /* border : 4px solid blue; */
}
 .video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before, .video-js .vjs-control:focus{
  /* text-shadow: none !important; */
}

/* .video-player .video-js .vjs-button > .vjs-icon-placeholder:before {
  
  font-size: 4vw !important;
  line-height: 8vw !important; 
} */

/* .video-player .video-js  .vjs-progress-control {
  flex: none !important;
  width: 50vw !important;
} */
.video-player .video-js .showTimeline {
  visibility: hidden !important;
}
/* .video-player .video-js .vjs-remaining-time.vjs-time-control.vjs-control {
  width: 10vw !important;
  padding-inline: 0 !important;
} */
/* .video-player .video-js.vjs-layout-x-small .vjs-progress-control,
.video-player .video-js.vjs-layout-tiny .vjs-progress-control {
  
  display: flex !important; 
  align-items: center !important;
} */


/* .vjs-control.vjs-button.vjs-fullscreen > .vjs-icon-placeholder:before{
  font-family: VideoJS;
  content: "\f108" !important;
  cursor : pointer;
}

.vjs-control.vjs-button.vjs-exitscreen > .vjs-icon-placeholder:before{
  font-family: VideoJS;
  content: "\f109" !important;
  cursor : pointer;
} */

.vjs-progress-control.vjs-control.disabled{
  display: flex !important;
}

.vjs-custom-control-spacer.vjs-spacer{
  display : none !important;
} 

.textarea {
  width: 100%;
  height: 100%;
  border-radius: 0%;
  padding: 0px;
  border: 0px;
  background-color: transparent;
}
/* .video-player .video-js.vjs-layout-small .vjs-current-time,
.video-player .video-js.vjs-layout-small .vjs-time-divider,
.video-player .video-js.vjs-layout-small .vjs-duration,
.video-player .video-js.vjs-layout-small .vjs-remaining-time,
.video-player .video-js.vjs-layout-small .vjs-volume-control,
.video-player .video-js.vjs-layout-x-small .vjs-current-time,
.video-player .video-js.vjs-layout-x-small .vjs-time-divider,
.video-player .video-js.vjs-layout-x-small .vjs-duration,
.video-player .video-js.vjs-layout-x-small .vjs-remaining-time,
.video-player .video-js.vjs-layout-x-small .vjs-volume-control,
.video-player .video-js.vjs-layout-tiny .vjs-current-time,
.video-player .video-js.vjs-layout-tiny .vjs-time-divider,
.video-player .video-js.vjs-layout-tiny .vjs-duration,
.video-player .video-js.vjs-layout-tiny .vjs-remaining-time,
.video-player .video-js.vjs-layout-tiny .vjs-volume-control {
  display: block !important;
} */

.video-player .video-js  .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-vertical,
.video-player .video-js  .vjs-volume-panel:active .vjs-volume-control.vjs-volume-vertical,
.video-player .video-js  .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-vertical,
.video-player .video-js  .vjs-volume-panel .vjs-volume-control:active.vjs-volume-vertical,
.video-player .video-js 
  .vjs-volume-panel.vjs-hover
  .vjs-mute-control
  ~ .vjs-volume-control.vjs-volume-vertical,
.video-player .video-js 
  .vjs-volume-panel
  .vjs-volume-control.vjs-slider-active.vjs-volume-vertical {
    z-index : 100000;
    /* left: -6vw !important; */
}

/* .video-player .vjs-remaining-time.vjs-time-control.vjs-control.hide{
  display: none !important;
} */

/* .video-player button.vjs-play-control.vjs-control.vjs-button.vjs-paused,.video-player button.vjs-play-control.vjs-control.vjs-button.vjs-playing{
  height : 0px !important;
}
.video-player .vjs-control-bar button{
  height: 0px !important;
} */

input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

div:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

span:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

button:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.replay-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width : 6vw;
  height: 6vw;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;;
  color: black;
  border-radius: 10vw;
  cursor: pointer;
  z-index: 100000;
}

.replay-content{
  height: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-wrapper{
  display: inline-block;
  height: 100%;
  width: 100%;
}
a{
  text-decoration: none;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust transparency as needed */
  z-index: 990;
}

.modal-content {
  /* min-width: 15vw; */
  top : 40%;
  left : 40%;
  background-color: white;
  padding: 1vw;
  padding-top: 1vw;
  border-radius: 1.5vw;
  z-index : 1000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.modal-container{
  position: relative;
}
.modal-description{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width : 90vw;
}
/* .content{
  font-size: 2.5vw;
}
.responseImg{
  width : 5vw;
} */
.loader {
  position: absolute;
  
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.5);
  transform: rotate(45deg);
  overflow: hidden;
}
.loader:after{
  content: '';
  position: absolute;
  inset: 8px;
  margin: auto;
  background: #222b32;
}
.loader:before{
  content: '';
  position: absolute;
  inset: -15px;
  margin: auto;
  background: #de3500;
  animation: diamondLoader 2s linear infinite;
}
@keyframes diamondLoader {
  0%  ,10% {
    transform: translate(-64px , -64px) rotate(-45deg)
  }
  90% , 100% {
    transform: translate(0px , 0px) rotate(-45deg)
  }
}


