.detail_menu{
    z-index: 999;
}




/*********************Ant Design Color Picker ********************************/
.ant-color-picker-color-block{
    width: 22px !important;
    height: 19px !important;
}
.fill_color { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 25px;
    background-color: transparent;
    cursor: pointer;
    border : 1px;
}

.fill_color::-webkit-color-swatch {
    border-radius: 3px;
    border: none;
}
.fill_color::-moz-color-swatch {
    border-radius: 3px;
    border: none;
}
.ant-color-picker-handler {
    border : 2px solid #b9b1b1 !important;
}
.ant-color-picker-slider-group {
    padding-left: 8px;
}
.ant-color-picker-color-block{
    margin-right: 2px;
    border-radius: 0% !important;
    border : 1px solid #414141 !important;
}
.ant-color-picker-input-container{
    padding-right: 5px;
    padding-bottom: 5px;
}
.ant-color-picker-inner-panel{
    padding : 10px;
}
.ant-color-picker-input-container .ant-input-number .ant-input-number-handler-wrap{
    height: 20px !important;
}
.ant-color-picker-trigger-active{
    box-shadow: none;
}
/****************************************************************/
.bg_color { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.bg_color::-webkit-color-swatch {
    border-radius: 3px;
    border: none;
}
.bg_color::-moz-color-swatch {
    border-radius: 3px;
    border: none;
}

