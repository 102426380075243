.ant-d-select-s .ant-select{
    width: 22rem !important;
}

@media not all and (min-width: 768px) { 
    .ant-d-select-s .ant-select{
        width: 19rem !important;
    }
   }

.ant-d-select-s .ant-select-selector .ant-select-selection-item {
    line-height: 36px !important;
    /* width: 18rem !important; */
}

.ant-d-select-s .ant-select-selector {
    height: 40px !important;
}

.ant-d-select-s .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px !important;
}