/* *********ORGANIZATION SELECT STYLES ***************** */
.ant-d-select .ant-select{
    width: 18rem !important;
    font-family: 'Inter', sans-serif !important;
}
.ant-d-select .ant-select-selector .ant-select-selection-item {
    line-height: 36px !important;
    font-family: 'Inter', sans-serif !important;
    /* width: 18rem !important; */
}

.ant-d-select .ant-select-selector {
    height: 40px !important;
    font-family: 'Inter', sans-serif !important;
}

.ant-d-select .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px !important;
    font-family: 'Inter', sans-serif !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-search{
  padding-top: 4px;
  font-family: 'Inter', sans-serif !important;
}
/* *********ORGANIZATION FORM STYLES ***************** */
.org-form .ant-form-vertical .ant-form-item-label {
    font-weight: 500 !important;
}

.zip-input .ant-input-number{
    width: 288px !important;
}

.zip-input .ant-input-number .ant-input-number-input,
.gst-input .ant-input-number .ant-input-number-input,
.tax-input .ant-input-number .ant-input-number-input{
    height: 40px !important;
}
/* .org-form .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    font-weight: 500 !important;
} */
.ant-d-select .ant-select{
    margin-top: 0px !important;
}

 /****************************Mobile Field Styling**********************/
 .PhoneInputCountry{
    display: flex;
    padding-top: 5px;
    flex-direction: row-reverse;
  }
  .PhoneInputCountrySelect{
    position: relative;
    top : 8px;
    width : 18px;
    height: 9px;
    border: none;
  }
  .PhoneInputCountryIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width : 24px;
    height: 24px;
  }
  .PhoneInputInput{
    width: 100%;
    padding-left: 2px;
    height: 100%; margin-top: 1px;
    padding-top: 6px;
    padding-bottom: 9px;
    border: none;
    outline: none;
  }
  /******************OTP Dialog Styling************************/
  
  .otpContainer input{
    font-size: 24px;
    font-weight: 500;
    width: 44px !important;
    height: 44px !important;
    background: #FCFCFC;
    border: 1px solid #BCBBBB;
    margin-right: 18px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .otpContainer input:focus-visible{
    width: 44px !important;
    height: 44px !important;
    background: #FCFCFC;
    /* border: 1px solid #0053e1 !important; */
    margin-right: 18px;
    /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); */
    border-radius: 4px;
  }
  .otpContainer span{
    display: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search{
    padding-top: 4px;
  }

.custom-class{
  margin-left: 77%;
  margin-top: 4%;
  width: 320px;
}
.ant-message .ant-message-notice .ant-message-notice-content:where(.css-dev-only-do-not-override-zjzpde)[class^="ant-message"] [class^="ant-message"], :where(.css-dev-only-do-not-override-zjzpde)[class*=" ant-message"] [class^="ant-message"], :where(.css-dev-only-do-not-override-zjzpde)[class^="ant-message"] [class*=" ant-message"], :where(.css-dev-only-do-not-override-zjzpde)[class*=" ant-message"] [class*=" ant-message"]{
  display: flex;
  align-items: self-start;
  text-align: left;
}

.ant-message .ant-message-notice .ant-message-success>.anticon{
  margin-top: 5px;
}


/* PHONE NUMBER INTERNANTION CSS STYLES */
.phone-input-profile .react-international-phone-input-container input{
  height: 40px;
  width: calc(30.75rem - 60px);
  font-size: 16px;
}

@media not all and (min-width: 768px) { 
  .phone-input-profile .react-international-phone-input-container input{
    height: 40px;
    width: 80%;
    font-size: 16px;
  }
 }

.phone-input-profile .react-international-phone-input-container .react-international-phone-country-selector button{
  height: 40px;
  width: 60px;
}

.phone-input-profile .react-international-phone-input-container .react-international-phone-country-selector .react-international-phone-country-selector-dropdown {
  width: 30.75rem;
}

.phone-input-profile .react-international-phone-input-container .react-international-phone-country-selector button:active{
  height: 40px;
  width: 60px;
}

.phone-input-profile-form .ant-row .ant-col {
  overflow: inherit !important;
}

/* PHONE NUMBER INTERNANTION CSS STYLES */
.phone-input-profile-onboarding .react-international-phone-input-container input{
  height: 40px;
  width: calc(400px - 60px);
  font-size: 16px;
}

.phone-input-profile-onboarding .react-international-phone-input-container .react-international-phone-country-selector button{
  height: 40px;
  width: 60px;
}

.phone-input-profile-onboarding .react-international-phone-input-container .react-international-phone-country-selector .react-international-phone-country-selector-dropdown {
  width: 400px;
}

.phone-input-profile-onboarding .react-international-phone-input-container .react-international-phone-country-selector button:active{
  height: 40px;
  width: 60px;
}

@media not all and (min-width: 640px) { 
  .phone-input-profile-onboarding .react-international-phone-input-container input{
    height: 40px;
    width: calc(400px - 180px);
    font-size: 16px;
  }
 }


.industry-select .ant-select-single .ant-select-selector{
  border-radius: 6px !important;
}

.input-profile .ant-input-disabled {
  border-color: #d9d9d9 !important;
}

.submit-request-select .ant-select-single .ant-select-selector{
  border-radius: 6px !important;
}