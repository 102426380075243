.bars {
    flex-basis: 33.3%;
    height: 3px;
  }
  
  .industry-details ul li {
    margin-right: 10px;
    padding: 8px 16px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #e7e7e7;
  }
  .ant-select-selector {
    font-size: 16px !important;
    text-align: left;
  }
  /* .ant-btn-default:not(:disabled):hover {
    color: #ffffff;
    border-color: #ffffff;
  } */
  .skip-link,
  .back-link {
    margin-bottom: 0 !important;
  }
  .onboardLoader > div {
    width: 33.333% !important;
    position: absolute !important;
    top: 69px !important;
  }
  .onboardLoader > div > div > div {
    /* display: none !important; */
    box-shadow: none !important;
  }
  .onboardLoader2 > div {
    width : 33.33% !important;
    position: absolute !important;
    top: 69px !important;
    left: 33% !important;
  }
  .onboardLoader2 > div > div > div {
    /* display: none !important; */
    box-shadow: none !important;
  }
  .onboardLoader3 > div {
    width: 33.99% !important;
    position: absolute !important;
    top: 69px !important;
    left: 66% !important;
  }
  .onboardLoader3 > div > div > div {
    /* display: none !important; */
    box-shadow: none !important;
  }
  
  .PhoneInputCountry {
    display: flex;
    padding-top: 5px;
    flex-direction: row-reverse;
  }
  .PhoneInputCountrySelect {
    position: relative;
    top: 8px;
    width: 18px;
    height: 9px;
  }
  .PhoneInputCountryIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .PhoneInputInput {
    width: 100%;
    padding-left: 2px;
    height: 100%;
    margin-top: 1px;
    padding-top: 6px;
    padding-bottom: 9px;
    border: none;
    outline: none;
  }
  
  .otpContainer input {
    width: 44px !important;
    height: 44px !important;
    background: #fcfcfc;
    border: 1px solid #1c67e9;
    margin-right: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  .otpContainer span {
    display: none;
  }
  
  .otpelement {
      animation: fade-in 1.3s ease-in-out;
  }
  
  @keyframes fade-in {
      from {
        opacity: 0;
        transform: translateX(20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
  }
  
  .industryInput{
      animation: input-appear 0.5s ease-out;
  }
  
  @keyframes input-appear {
      0% {
        opacity: 0;
        transform: translateY(-50px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
  }
  
  .btnup{
      animation: btn-up 0.5s ease-out;
  }
  
  @keyframes btn-up {
      0% {
        opacity: 0;
        transform: translateY(50px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
  }
  
  .btndown{
      animation: btn-down 0.5s ease-out;
  }
  
  @keyframes btn-down {
      0% {
        opacity: 0;
        transform: translateY(-50px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
  }


  /* *********ORGANIZATION SELECT STYLES ***************** */
.ant-d-select-org .ant-select{
  width: 25rem !important;
  margin-top: 0px !important;
}
@media not all and (min-width: 640px) { 
  .ant-d-select-org .ant-select{
    width: 270px !important;
    margin-top: 0px !important;
  }
 }
.ant-d-select-org .ant-select-selector .ant-select-selection-item {
  line-height: 36px !important;
  border-radius: 4px !important;
}

.ant-d-select-org .ant-select-single .ant-select-selector {
  border-radius: 4px !important;
}

.ant-d-select-org .ant-select-selector {
  height: 40px !important;
}

.ant-d-select-org .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}
.skip-link,
  .back-link {
    margin-bottom: 0 !important;
  }