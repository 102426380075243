.video-js.editor-player .vjs-control-bar{
    position: absolute !important;
    bottom : -50px !important;
    display: flex !important;
    z-index: 100000;
    opacity: 1 !important;
    pointer-events: auto !important;
}

.vjs-big-play-button{
	z-index : 100000;
}

.video-js.editor-player{
    z-index: 0;
}
.show{
    display : block !important;
    padding-right: 0 !important;
}
.video-js.editor-player .vjs-time-control{
    min-width: 1em !important; 
}
.video-js.editor-player .vjs-remaining-time{
    width : 60px !important;
    -webkit-user-select: none;
}
.showTimeline{
    visibility:  hidden !important;
}

.video-js.editor-player .vjs-big-play-button{
    /* border-radius: 0 !important; */
    display: none !important;
}

input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}

select:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}

input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}

select:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}
.vjs-poster img{
    position: absolute;
    background-color: black !important;
}

.vjs-play-progress.vjs-slider-bar>.vjs-time-tooltip{
   top : 20px;
}

.vjs-mouse-display>.vjs-time-tooltip{
    top : 20px;
}
.vjs-mouse-display>.vjs-volume-tooltip{
    top : 20px;
}