@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body, html {
    font-family: 'Inter', sans-serif !important;
} */

.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.opt-container {
  @apply flex justify-between gap-4;
}
.opt-container input {
  @apply !mr-0 flex-1 py-6 outline-none;
  border-radius: 4px !important;
  border: 1px solid #848fc9 !important;
  /* @apply !mr-0 flex-1 py-5 outline-none; */
  width: 44px !important;
  height: 58px !important;
  font-size: 20px !important;
  font-weight: 500;
}
.opt-container input:focus {
  outline: 1px solid #1963e1;
  border: 1px solid #1963e1;
  box-shadow: 0px 0px 4px 0px #19191a;
  border-color: transparent;
  outline-offset: 0;
  /* width: 24px !important; */
  /* height: 60px !important; */
  /* font-size: 26px !important; */
  /* font-weight: 600 !important; */
}
@media not all and (min-width: 768px) { 
  .opt-container input {
    
    width: 35px !important;
    height: 50px !important;
    
  }
 }
@font-face {
  font-family: "Lobster";
  src: local("Lobster"), url("./saasWebsite/Fonts/Lobster-Regular.ttf") format("truetype");
  font-weight: 400;
}

.editor-input {
  @apply !text-[#EBE9E9] !font-sans !bg-[#0d0d10] placeholder:text-[#d8d5d5] !border-[#5e5e5e] hover:!border-[#dce2ea] focus:!border-[#056DE8] focus:hover:!border-[#056DE8] rounded-none;
}

svg, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
}