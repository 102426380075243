.ant-input-number.editor-inum {
  background-color: transparent;
  border-color: #5e5e5e !important;
  border-radius: 0px !important;
  background-color: #0d0d10 !important;
}

.ant-input-number.editor-inum:hover {
  border-color: #ffffff !important;
}
.ant-input-number-focused.editor-inum {
  border-color: #056de8 !important;
}
.ant-input-number-focused.editor-inum:hover {
  border-color: #056de8 !important;
}
.ant-input-number.editor-inum .ant-input-number-handler {
  border-inline-start: none !important;
}

.ant-input-number.editor-inum .ant-input-number-handler-down {
  border-block-start: none !important;
}

.ant-input-number.editor-inum .ant-input-number-handler-wrap {
  width: 18px !important;
  background: transparent !important;
  opacity: 1;
  color: white;
  border-color: #0d0d10 !important;
}

.ant-input-number.editor-inum .ant-input-number-handler-wrap:hover {
  /* background: #243B53 !important; */
  border-color: #0d0d10 !important;
}

.ant-input-number-affix-wrapper {
  /* padding-inline-start: 4px !important; */
  /* border-color: #FFF !important; */
  border: none;
  /* background: #3A5A7C !important; */
  /* color: #fff !important; */
}

.ant-input-number-affix-wrapper:hover {
  /* border-color: #E2E5E8 !important; */
  /* background: #3A5A7C !important; */
}

.ant-input-number.editor-inum .ant-input-number-input {
  /* border-top-right-radius: 4px !important; */
  /* border-bottom-right-radius: 4px !important; */
  /* border-radius: 3px !important; */
  /* background: #243B53 !important; */
  color: white;
  text-align: left !important;
  padding-left: 8px !important;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-input {
  /* border-color: #cdcbcb !important; */
  /* border-color: #dddddd !important; */
  border-radius: 4px !important;
}
.ant-input-number.editor-inum .ant-input-number-handler-up-inner svg {
  color: white !important;
}
.ant-input-number.editor-inum .ant-input-number-handler-down-inner svg {
  color: white !important;
}

/* :where(.css-dev-only-do-not-override-w8mnev).ant-input-number-focused {
  border-color: #056DE8 !important;
} */
.ant-input-number.editor-inum .ant-input-number-handler:hover {
  height: 40%;
}
.ant-input-number.editor-inum .ant-input-number-handler-down:hover {
  height: 40%;
}
.ant-input-number.editor-inum .ant-input-number-handler-wrap:hover {
  /* background: #243B53 !important; */
  /* border-color: #3A5A7C !important; */
  transform: scale(1);
}
/*****************PopOver*************************/
.ant-popover {
  padding: 0px !important;
}
.ant-popover-inner {
  padding: 0px !important;
}
.ant-popover-arrow {
  display: hide !important;
}
.ant-popover-inner-content {
  padding: 0px !important;
}
.ant-input-number.timer {
  height: 25px !important;
  display: flex;
  align-items: center !important;
}
.ant-input-number.timer .ant-input-number-input {
  color: #ffffff !important;
  height: 100% !important;
}

.timer0 .ant-input-number-handler-wrap {
  /* display: none !important; */
}
.ant-input-number.timer0 {
  height: 25px !important;
  display: flex;
  align-items: right !important;
}
.ant-input-number.timer0 .ant-input-number-input {
  color: #ffffff !important;
  height: 100% !important;
  text-align: center !important;
}

.timer0 .ant-input-number-handler-wrap {
  /* display: none !important; */
}
.timer .ant-input-number-handler-wrap {
  display: none !important;
}

.action-select .ant-select {
  border: none !important;
}
.action-select .ant-select-selector {
  background-color: #0d0d10 !important;
  /* background-color: #050506 !important; */
  border-color: #5e5e5e !important;
  color: #ffffff !important;
}
.action-select .ant-select-selector:focus-within:hover {
  border-color: #056de8 !important;
}
.action-select .ant-select-selector:focus-within {
  border-color: #056de8 !important;
}
.action-select .ant-select-selector:hover {
  border-color: #dce2ea !important;
}
.action-select .ant-select-arrow svg {
  color: #ffffff;
}
.action-select .ant-select-selection-item {
  color: #fff !important;
}
.ant-select-dropdown.action-select {
  /* background-color: #4a4a4a !important; */
  background-color: #050506 !important;
  /* color: red !important; */
  border-radius: 0px;
  max-height: 100px !important;
  padding: 0px !important;
  overflow-y: scroll;
}

.action-select .ant-select-selector {
  border-radius: 0 !important;
}

.ant-select-dropdown.action-select .ant-select-item {
  border-radius: 0px !important;
  color: white !important;
}
.ant-select-dropdown.action-select
  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background-color: #484444 !important;
  /* color: red !important; */
}

.ant-select-dropdown.action-select
  .ant-select-item.ant-select-item-option.ant-select-item-option-active::hover {
  background-color: #484444 !important;
  /* color: red !important; */
}

/* .ant-select-dropdown.action-select
  .ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
  background-color: red !important;
  
} */
.ant-select:hover .ant-select-selector {
  box-shadow: none !important;
  
}
.action-select .ant-select:focus .ant-select-selector {
  box-shadow: none !important;
}
.action-select .ant-select-selector {
  box-shadow: none !important;
}
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
  display: none !important;
}

.custom-height-400 {
  height: 400px !important;
}

.custom-height-200 {
  height: 200px;
}
.ant-slider:hover .ant-slider-rail {
  background-color: aliceblue !important;
}
.ant-slider .ant-slider-rail {
  background-color: aliceblue !important;
}
.ant-input-disabled {
  border-color: #2b2b2c !important;
}

.audio-player-container {
  text-align: center;
  padding: 2px;
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px 0px;
}

/* Audio Title */
.audio-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Audio Controls */
.audio-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Audio Element */
.audio-element {
  height: 30px;
  width: 100%;
  max-width: 300px;
}

.audio-controls audio::-webkit-media-controls-panel {
  background-color: #626364; /* Change to your desired background color */
  border-radius: 0%; /* Apply a border radius of 100% for a circular shape */
}

.audio-element *{
  border-radius: 0% !important;
}

.right-drawer {
  position: relative;
  top:0;
  right : -300px;
  /* display : none; */
  transition: right 0.5s ease-in-out;
}

.right-drawer.open {
  right : 0;
  /* display : block; */
  transition: right 0.5s ease-in-out;
}

.tempInfo .rc-collapse-header{
  padding : 10px 0px;
  padding-left: 0.5rem;
  cursor : pointer;
}

/* .xyz .rc-collapse-content-hidden{
  display: none;
} */