.ant-dropdown {
  border-radius: 6px;
  width: 206px !important;
  background-color: #2b2b2c !important;
}
.ant-dropdown-menu {
  color: #ffffff !important;
  background-color: #2b2b2c !important;
}
.ant-drawer-header-close-only {
  display: none !important;
}

.filter-div .ant-drawer .ant-drawer-content-wrapper {
  top: 60px !important;
}

.librarysidebar {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.ant-drawer-body {
  padding: 0 !important;
}
.card {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.ant-dropdown-arrow::before {
  background-color: #2b2b2c !important;
}
.box {
  background: #fefefe;
  border: 1px solid #e0dcdc;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.04);
}
.ant-divider {
  margin: 4px 0 !important;
}
.customization_box {
  background: #f6fffa;
  border: 1px solid #dcdfe0;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.mytemplate {
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: box-shadow 0.6s;
}
.mytemplate:hover {
  box-shadow: 0 0 40px rgba(33, 33, 33, 0.2);
}
.ant-popover {
  padding: 0 !important;
}
.ant-popover-inner {
  padding: 0 !important;
}
.ant-popover-inner-content {
  padding: 8px !important;
}
.template-box {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}
.api:hover {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.ant-select-selection-item {
  font-size: 13px !important;
  font-family: "Inter", sans-serif !important;
}
.ant-select-item-option-content {
  font-size: 13px !important;
  font-family: "Inter", sans-serif !important;
}
.ant-popover-arrow {
  margin-top: 0px;
  background: transparent !important;
  border-width: 0 !important;
}
.ant-select-arrow {
  color: black !important;
}
.ant-dropdown-menu-item {
  padding: 0px !important;
  font-family: "Inter", sans-serif !important;
}
.ant-dropdown {
  animation: pulse 0.3s linear 1 !important;
  font-family: "Inter", sans-serif !important;
}

.ant-popover .ant-popover-arrow {
  display: none !important;
}
.clone:hover {
  background-color: #e6f0f6;
  border-radius: 5px;
  font-weight: 500;
  padding-left: 5px;
}
.clone:active {
  background-color: #cee4f2;
}
.ant-popover-inner-content {
  padding: 4px !important;
}
.ant-table-thead .ant-table-cell {
  background-color: #eaeef4 !important;
  color: #093b93 !important;
  font-weight: 500 !important;
}
.summary {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.desc {
  line-height: 18px;
}
.ant-select-item-option:hover {
  background-color: #e6f0f6 !important;
}
.ant-select-item-option-active {
  background-color: #e6f0f6 !important;
}
.ant-select-item-option-selected {
  background-color: #cee4f2 !important;
  font-weight: 500 !important;
}
.delete-dlg {
  position: relative;
  width: 100%;
  height: 100%;
}
p {
  margin: 0px;
}

.container p {
  color: red;
  text-align: center;
}

.popover-assets:active {
  background-color: #cee4f2;
}

.popover-assets {
  border-radius: 5px;
  padding-left: 5px;
  color: #6b6b6b;
}
