/* .design-selector .ant-select-selector{
    background: none !important;
    border: none !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 400;
    text-align: start !important;
}
.design-selector .ant-select-arrow{
    color: white !important;
} */
.slider .ant-slider-rail{
    background-color: #EEEEEE !important;
}
.ant-segmented-item-selected{
    background-color: #1C68EB!important;
}
.ant-segmented-thumb-motion-appear{
    background-color: #1C68EB!important;
    /* transition: none !important; */
}
.ant-segmented-group{
    width : 120px !important;
}
.ant-segmented-group label{
    width : 30% !important;
    height: 100% !important;
}
.chrome-picker {
   width : 250px!important;
   position : absolute !important;
   top : 120px;
   left : 00px;
}