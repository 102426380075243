
.price-switch-span .ant-switch.ant-switch-checked,
.price-switch-span .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled),
.price-switch-span .ant-switch,
.price-switch-span .ant-switch:hover:not(.ant-switch-disabled) {
  background: #01cc83;
}


.enterpriseImage {
  background: url("../../../assests/commonImages/userModuleImages/enterprisePlanImg.png") no-repeat;
  background-size: contain;
  background-position: right;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
