.dashboard-dropdown .ant-select-selector {
    font-size: 11px !important;
}

.dashboard-dropdown .ant-select-selection-item{
    font-size:  12px !important;
}

.dashboard-dropdown .ant-select-single .ant-select-selector .ant-select-selection-search {
    padding-top: 0px !important;
}