.tool_bar {
  width: 250px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  background-color: lightskyblue;
  z-index: 999;
}
.tool_bar > * {
  width: 100%;
  height: 25px;
}
.progressbar.ant-progress-line {
  margin-bottom: 0px !important;
  display: block !important;
  /* position: absolute !important;
    top : 0 !important */
}
.progressbar.ant-progress .ant-progress-outer,
.progressbar.ant-progress .ant-progress-inner {
  display: block !important;
}
.progressbar .ant-progress-bg {
  height: 4px !important;
}

.dropable_div {
  margin-top: 25px;
  background-color: cornflowerblue;
  user-select: none;
}

input[type="radio"] {
  border: 2px solid #00d8be !important;
  height: 16px;
  width: 16px;
  background-color: #00d8be;
}

/* .toolbar_dropdown .ant-select-selector{
    cursor: move !important;
} */

select[disabled] {
  background-color: #2d4966;
  color: #ffffff;
}

/* .upload-label {
  display: flex;
  width: 75px;
  height: 75px;
  line-height: 50px;
  border: 2px dashed #999;
  border-radius: 8px;
  font-size: 8px;
  color: #999;
  cursor: pointer;
} */

.upload-label3,.upload-label {
  display: flex;
  width: 30px;
  height: 30px;
  line-height: 50px;
  /* border: 2px solid #999; */
  border-radius: 8px;
  /* font-size: 8px; */
  color: #999;
  cursor: pointer;
}

/* .upload-label:hover {
  background-color: #1890ff;
} */

#upload-file1,
#upload-file2,
#upload-file3 {
  display: none;
}

.music-panel {
  height: 20px;
  width: 20px;
  margin-top: 4px;
}
.custom-audio-player {
  /* display: inline-block; Ensure it takes up only as much space as needed */
}

/* Hide the timeline and volume controls */
.custom-audio-player audio::-webkit-media-controls-timeline,
.custom-audio-player audio::-webkit-media-controls-volume-slider,
.custom-audio-player audio::-webkit-media-controls-mute-button,
.custom-audio-player audio::-webkit-media-controls-overflow-button,
.custom-audio-player audio::-webkit-media-controls-playback-rate,
.custom-audio-player audio::-webkit-media-controls-fullscreen-button {
  display: none;
}

/* .custom-audio-player audio::-webkit-media-controls-panel, */
.custom-audio-player audio::-webkit-media-controls-timeline-container,
.custom-audio-player audio::-webkit-media-controls-current-time-display,
.custom-audio-player audio::-webkit-media-controls-time-remaining-display,
.custom-audio-player audio::-webkit-media-controls-timeline,
.custom-audio-player audio::-webkit-media-controls-volume-slider-container,
.custom-audio-player audio::-webkit-media-controls-volume-slider,
.custom-audio-player audio::-webkit-media-controls-seek-back-button,
.custom-audio-player audio::-webkit-media-controls-seek-forward-button,
.custom-audio-player audio::-webkit-media-controls-fullscreen-button,
.custom-audio-player audio::-webkit-media-controls-rewind-button,
.custom-audio-player audio::-webkit-media-controls-return-to-realtime-button,
.custom-audio-player audio::-webkit-media-controls-toggle-closed-captions-button,
.custom-audio-player audio input[aria-label="show more media controls"]
 {
  display: none;
}
.custom-audio-player audio input[type="button"][aria-label="show more media controls"][title="more options"][aria-haspopup="menu"][pseudo="-internal-media-controls-overflow-button"] {
  display: none;
}
.custom-audio-player audio::-webkit-media-controls-play-button{
  display: block !important;
}

.custom-audio-player audio::-webkit-media-controls-enclosure {
  border-radius: 0px;
  width : 33px !important;
}

.custom-audio-player audio::-webkit-media-controls-current-time-display,
.custom-audio-player audio::-webkit-media-controls-time-remaining-display {
  font-size: 0; /* Hide the time display */
  display: none;
}

/* Style the play button (you can customize this further) */
.custom-audio-player audio::-webkit-media-controls-play-button {
  background-color: #3498db; /* Change the play button color */
  border: none;
  width: 30px; /* Set the desired button width */
  height: 30px; /* Set the desired button height */
  border-radius: 50%; /* Make it circular */
}

.custom-audio-player audio::-webkit-media-controls-panel {
  background-color: #131317; /* Change to your desired background color */
  border-radius: 0%; /* Apply a border radius of 100% for a circular shape */
  min-width: 33px !important;
  padding: 0px !important;
}
.custom-audio-player audio::-webkit-media-controls-volume-control-container.closed {
  min-width: 0px !important;
}


.my-masonry-grid {
  display: flex;
  margin-left: -5px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 5px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 5px;
}

.dragged-image {
  width : 10px !important;
  height: 10px !important;
}

.editor-collapse .anticon.anticon-right.ant-collapse-arrow{
  font-size: 14px !important;
}
.editor-collapse svg{
  color : white !important;
}

