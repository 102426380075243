/* ************** GREY BUTTON STYLES ****************** */
/* .ant-btn-default:not(:disabled):hover{
    color: black !important;
    border-color: #E8E8E8 !important;
    background: linear-gradient(90.69deg, #F6F6F6 2.38%, #E6E6E6 73.87%) !important;
    border: 1px solid #DADADA !important;
}

.ant-btn-default:not(:disabled):active{
    background: #E6E6E6 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18) !important;
} */

/* ************** LOGOUT BUTTON STYLES IN LOGOUT MODAL ****************** */
.white-bg-btn:hover {
  border-radius: 4px;
  border: 1px solid #1c68eb;
  background: linear-gradient(90deg, rgba(246, 249, 254, 0) 0%, #f6f9fe 100%);
  box-shadow: 0px 4px 4px 0px rgba(21, 96, 225, 0.08);
}

.white-bg-btn:active {
  border-radius: 4px;
  border: 1px solid #0943a6;
  background: linear-gradient(90deg, rgba(246, 249, 254, 0) 0%, #f6f9fe 100%);
  box-shadow: 0px 4px 4px 0px rgba(21, 96, 225, 0.08);
}

/* ************** NORMAL DISABLED INPUT CSS ****************** */
.ant-d-select .ant-input[disabled] {
  cursor: default !important;
}

/* ************** GLOBAL SELECTOR DISABLED INPUT CSS SELECT ANT D ****************** */
.ant-select-disabled.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  cursor: default !important;
}

/* ************** GLOBAL SELECTOR INPUT CSS SELECT ANT D ****************** */
/* .ant-select-single .ant-select-selector {
  border-radius: 4px !important;
} */

.template-select .ant-select-single .ant-select-selector {
  border-radius: 4px;
}

/***************************************************************************************************************************************/
/******************************** MODAL CSS STARTS *************************************************************************************/
/***************************************************************************************************************************************/

/* *************************** LOGOUT MODAL ( WHEN CLICK LOGOUT BUTTON) STARTS *************************** */
.logout-modal .ant-modal-content {
  padding: 36px 24px !important;
}

.logout-modal .ant-modal-footer {
  display: none;
}
/* *************************** LOGOUT MODAL ( WHEN CLICK LOGOUT BUTTON) ENDS *************************** */

/* ********************** DELETE ACCOUNT MODAL IN USER PROFILE *********************** */
.delete-account-modal .ant-modal-footer {
  display: none;
}

.generate-preview-link-modal .ant-modal-footer {
  display: none;
}

.generate-preview-link-modal .ant-modal-content {
  border-radius: 4px !important;
  padding: 12px !important;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.generate-preview-link-modal .ant-modal-content .ant-modal-close {
  top: 14px !important
}

.generate-preview-link-modal-new .ant-modal-footer {
  display: none;
}

.generate-preview-link-modal-new .ant-modal-content {
  border-radius: 4px !important;
  padding: 12px 0 !important;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.generate-preview-link-modal-new .ant-modal-content .ant-modal-close {
  top: 14px !important
}

/* QR CODE LINK */
.link-qrcode .ant-qrcode {
  padding:  4px !important;
  border: none !important;
}

/* ********************** EMAIL OTP MODAL IN USER PROFILE *********************** */
.otp-email-modal .ant-modal-footer {
  display: none;
}

/* ********************** PREVIEW MODAL (WEHN USER CLICK ON GLOBAL TEMPLATE FOR PREVIEW) *********************** */
.global-template-preview-modal .ant-modal-footer {
  display: none;
}

/* *************************** PROJECT MODAL ( WHEN WE SELECT A GLOBAL TEMPLATE) STARTS *************************** */
.project-modal .ant-modal-footer {
  display: none;
}

.project-modal .ant-modal-content {
  /* width: 480px;
    height: 396px; */
  padding: 24px !important;
  border-radius: 2px;
  background: #fff;
  /* margin: 0; */
}

.project-modal .ant-modal-title {
  color: #242424;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.project-button ant-btn .ant-btn-primary:disabled {
  background-color: black !important;
}
/* *************************** PROJECT MODAL ( WHEN WE SELECT A GLOBAL TEMPLATE) ENDS *************************** */

/* *************************** MEDIA IMPORT DIALOG ( REMOVE DEFAULT FOOTER)  *************************** */
.media-Modal .ant-modal-footer {
  display: none;
}

/* *************************** CLONE DIALOG ( REMOVE DEFAULT FOOTER)  *************************** */
.clone-Modal .ant-modal-footer {
  display: none;
}

/* *************************** API DIALOG ( REMOVE DEFAULT FOOTER)  *************************** */
.APIdoc-Modal .ant-modal-footer {
  display: none;
}

/* .APIdoc-Modal .ant-modal-content {
  padding: 20px 24px !important;;
} */

/* *************************** TEAMMATES DIALOG ( REMOVE DEFAULT FOOTER)  *************************** */
.InviteModal .ant-modal-footer {
  display: none;
}

/* *************************** Input Modal ( REMOVE DEFAULT FOOTER)  *************************** */
.Input-Modal .ant-modal-footer {
  display: none;
}

.global-template-preview-modal .ant-modal-content {
  padding: 20px 24px !important;
}

/* Change background color of body within iframe */
.global-template-preview-modal.preview-container iframe {
  background-color: red !important;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  background-color: #01CC83 !important;
}

.global-template-preview-modal.preview-container .preview-iframe .wrapper {
  width: 200px !important;
}


/* *************************** PROJECT DIALOG  *************************** */

.upload-zip-file .ant-modal-content {
  padding: 20px 24px !important;
}

.success-file-upload .ant-modal-content {
  padding: 32px 24px !important;
}

/* *************************** UPLOAD ASSET DIALOG  *************************** */

.upload-asset-modal ant-modal-content {
  padding: 20px 24px !important;
}

.create-template-modal .ant-modal-content {
  padding: 36px 24px 1px 24px !important;
}

.confirmModal .ant-modal-footer{
  display: none !important;
}


.create-brand-modal .ant-modal-content {
  padding: 36px 24px 36px 24px !important;
}


/***************************************************************************************************************************************/
/******************************** MODAL CSS ENDS *************************************************************************************/
/***************************************************************************************************************************************/

.simple-button-icon button:hover {
  background-color: transparent !important;
}

.template-name {
  max-width: 150px; /* Adjust the maximum width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* custom.css or custom.scss */
.truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slick-arrow {
  background-color: transparent !important;
  border-radius: 50% !important;
  /* font-size: 200px !important; */
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  top: 120px !important;
  z-index: 10;
}

.slick-prev {
  left: 8px !important;
}

.slick-next {
  right: 8px !important;
}


/* USED IN SEE ALL PAGE FOR CATEGORY SLIDER */

.ul {
  display: flex;
  align-items: center;
  list-style: none;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.ul::-webkit-scrollbar {
  background: transparent;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}
.ul .list-tag {
  background: #FF61F6;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  margin: 0 4px;
  white-space: nowrap;
}

.next {
  border: 0;
  color: #777;
  font-size: 24px;
  margin: 0 8px;
}

.prev {
  border: 0;
  color: #777;
  font-size: 24px;
  margin: 0 8px;
}


.sticky-container {
  background-color: #f7fafc;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  z-index: 30;
}

.sticky-container::before {
  content: '';
  width: 100%;
  height: 7px; /* Adjust this height to match your InputBox height */
  position: absolute;
  background-color: #f7fafc;
  top: -7px; /* Negative value to cover the InputBox */
  left: 0;
}

.sticky-container::after {
  content: '';
  width: 100%;
  height: 7px; /* Adjust this height to match your InputBox height */
  position: absolute;
  background-color: #f7fafc;
  top: 100%; /* Push content below the sticky bar */
  left: 0;
}

.input-box {
  display: none;
}

.sticky-container.sticky .input-box {
  display: block;
}

/* FOR SCROLL TO TOP */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: none; /* Initially hidden */
}

.scroll-to-top-button:hover {
  background-color: #0056b3;
}


.RangeDate .ant-picker-range-separator{
  padding-bottom: 6px;
}

.selectDateDiv:hover{
 border-color: #4095FE !important;
}

.RangeDate.ant-picker.ant-picker-borderless {
 background-color: white !important;
}


.searchInput.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
 border-color: #1C68EB !important;
}

.ant-input-affix-wrapper-focused.searchInput {
 border-color: #1C68EB !important;
}

.reportSummary.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white;
  /* border-color: #4096ff; */
}
.TableHead.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white;
  /* border-color: #4096ff; */
}

/* sidebar styles */
.active-link {
  text-decoration: none;
  color: #1560E1;
  font-weight: bold;
  background-color: #E6F0F6;
  border-radius: 8px;
}

.analytic.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
  background-color: #E6F0F6 !important;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
  background-color: #E6F0F6 !important;
}

.analytic.ant-menu-light .ant-menu-item-selected{
  background-color: white !important;
}

.analytic.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline{
  background-color: white;
  padding-left: 11px;
}

.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title {
  background-color: #E6F0F6;
}

.analytic.ant-menu-title-content{
  /* padding-bottom: -4px; */
}

.ant-menu-inline .ant-menu-item{
  line-height: 1.2;
}

.ant-menu-inline .ant-menu-item {
  height: 30px;
}

.ant-divider.divider-temp {
  border-color : #cccccc !important;
}

/* template slider css in template library */

.swiper-slide{
  width: auto !important;
}

.templateSlider .swiper-button-next,
.templateSlider .swiper-button-prev{
  opacity: 0.5;
  height: 0px !important;
}

.templateSlider .swiper-button-next:hover,
.templateSlider .swiper-button-prev:hover{
  opacity: 1;
}

/* text slider css in template library in navbar  */

.textSlider .swiper-button-next{
  background-color: #F7FAFC !important;
  height: 14px !important;
  width: 10px !important;
  right: 0px !important;
}

.textSlider .swiper-button-prev{
  background-color: #F7FAFC !important;
  height: 14px !important;
  width: 10px !important;
  left: 0px !important;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  display: none;
}

/* helpIcon drawer css in HelpNavbar  */

.helpDrawerSlider .swiper-button-prev{
  background-color: #f7fafc !important;
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
}

.helpDrawerSlider .swiper-button-next{
  background-color: #f7fafc !important;
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
}

.HelpDrawer .ant-drawer-mask{
  background: transparent !important;
}

.HelpDrawer .ant-drawer-header{
  height: 40px !important;
}

.HelpDrawer .ant-drawer-close{
  position: absolute !important;
  right: 6px !important;
}

/* --------------------- */

.StartingModal .ant-modal-footer{
  display: none;
}

.StartingModal.ant-modal .ant-modal-content {
  padding: 0px !important;
  border-radius: 0px !important;
}

.HelpDrawer .ant-drawer-mask{
  background: transparent !important;
}

.HelpDrawer .ant-drawer-header{
  height: 40px !important;
}

.HelpDrawer .ant-drawer-close{
  position: absolute !important;
  right: 6px !important;
}

.ongoingSwitch .ant-switch {
  background: #01CC83 !important;
}

.input-project .ant-input-disabled{
  border-color: #d9d9d9 !important;
}

/* PREVIEW MODAL QR CODE CSS */
.preview-qr .ant-qrcode {
  border: 1px dashed #D4C2B5 !important;
  background-color: #FFF0E5 !important;
}

.preview-qr .ant-qrcode canvas {
  height: 90px !important;
  width: 90px !important;
}

.MapBrandModal .ant-modal-footer {
  display: none;
}

.ant-modal.MapBrandModal {
  width: 368px !important;
  /* height: 417px !important; */
  background-color: white !important;
  padding-bottom: 0px;
  border-radius: 10px;
}

.mapBrandSwitch .ant-switch.ant-switch-checked {
  background: #65A513 !important;
}

.MapBrandModal .ant-modal-content{
      background-color: white !important;
      padding: 0px !important;
}

.mapBrandSwitch .ant-switch{
  width: 40px;
  height: 21px;
  border-radius: 27px;
}

 .mapBrandSwitch .ant-switch .ant-switch-handle{
  position: absolute;
  width: 14px;
  height: 14px;
  top: 3px;
}

.mapBrandSwitch .ant-switch .ant-switch-inner{
  position: absolute;
    top: -3px;
}

.filterDrawer .ant-drawer-header{
  display: none !important;
}

.fontl {
  font-family: "Lobster";
}

/* mobile responsive */
.msidebarDrawer .ant-drawer-header{
  display: none !important;
}

.templateCategorySelect .ant-select-selector{
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.showInputBox .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}

.popupCalendar .ant-picker-panels{
  display: flex !important;
  flex-wrap: wrap !important;
  width: 308px !important;
  /* height: 330px !important; */
  /* overflow: scroll !important; */
  overflow: auto !important;
  max-height: 320px !important; /* Adjust as needed */

}
.ribbon-2 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 2px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #0095ff;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
}

.cardtempCss:hover {
  box-shadow: 0 0 10px 3px #a2a8dd;
  /* filter: drop-shadow(0 0 10px #a2a8dd); */
}
